#faq {
    margin-top: 116px;


    .faq-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        justify-content: center;
    }

    .faq-container {
        background-color: #fff;
        margin: 25px;
        margin-top: 50px;
    }

    .faq-item {
        flex: 50%;
    }

    p {
        padding: 20px
    }
}

@media only screen and (max-width: 1090px) {
    #faq {
        margin-top: 0px;
    }

    .faq-grid {
        flex-direction: column;
        margin-top: 90px !important;
    }
}