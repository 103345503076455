#contact-us {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;

    .captcha-center {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .container {
        text-align: center;
        background-color: #4D333B;
        width: 600px;
        padding: 55px 25px;
        border-radius: 16px;
        position: relative;
        overflow: hidden;

        &::before {
            content: '';
            height: 450px;
            width: 450px;
            background-color: rgba(235, 235, 235, 0.192);
            border-radius: 50%;
            display: inline-block;
            position: absolute;
            top: -50%;
            left: -40%;
        }

        &::after {
            content: '';
            height: 450px;
            width: 450px;
            background-color: rgba(235, 235, 235, 0.192);
            border-radius: 50%;
            display: inline-block;
            position: absolute;
            bottom: -50%;
            right: -50%;
        }

        h2 {
            color: #fff;
            font-weight: normal;
        }

        p {
            color: #fff;
            margin: 30px 0px;
        }

        .button {
            background-color: #F7AA08;
            width: 70%;
            margin: 15px auto;
            padding: 12px 20px;
            border-radius: 50px;
            color: #fff;
            cursor: pointer;

        }

        input[type=text],
        select {
            width: 70%;
            padding: 12px 20px;
            margin: 8px 0;
            display: inline-block;
            border: 1px solid #ccc;
            border-radius: 50px;
            box-sizing: border-box;
        }
    }
}