#product-section {
    color: #2A2A2A;
    display: flex;
    justify-content: center;

    .second-heading {
        font-size: medium;
        color: #A31D42;
    }

    .scroll-container {
        width: 100%;

    }

    .content {
        margin-top: 3rem;
    }

    .level-1 {
        display: flex;
        list-style: none;
        padding: 0;

        .header-switch {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 90px;
            margin: 50px 0px;

            h2 {
                cursor: pointer;
                padding: 10px 30px;
                border-radius: 10px;
            }

            .selected-switch {
                border: solid 1px #333;
            }
        }
    }

    @media only screen and (min-width: 670px) {
        .level-1 {
            display: block;
        }
    }



    .level-2 {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        justify-content: space-evenly;
        gap: 100px;
        padding: 0;
    }


    .card {
        padding: 20px 30px;
        padding-bottom: 50px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 20px;
        text-align: center;
        width: 300px;
        font-weight: normal;
        animation: 0.5s ease-out 0s 1 popUp;
        position: relative;

        &:hover {
            box-shadow: rgba(100, 100, 111, 0.2) 0px 10px 41px 0px;
        }

        .price {
            position: absolute;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            font-size: 0.8rem;
            color: rgb(156, 156, 156);

            span {
                color: rgb(48, 48, 48);
            }
        }

        p {
            text-align: left;
        }


        h3 {
            margin-bottom: 10px;
            font-size: 1.5em;
        }

    }

    @keyframes popUp {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }



}