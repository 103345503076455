#blog-page {
    .spacer {
        margin-top: 78px;
    }

    .header-container {
        padding-top: 25px;
        text-align: center;
    }
}

.blog-grid {
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 135px;
}