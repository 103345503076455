#blog {
    width: 100%;

    .spacer {
        margin-top: 85px;
    }

    img {
        width: 620px;
        display: block;
        margin: 20px auto;
    }

    .date {
        color: #929292;
        font-size: 0.9rem;
        display: block;
    }

    span {
        font-size: 1.1rem;
    }

    .back-button {
        text-decoration: none;
        color: red;
    }

    .pure-g {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }

    @media (min-width: 768px) {
        .pure-g {
            width: 750px;
        }
    }

    @media (min-width: 992px) {
        .pure-g {
            width: 970px;
        }
    }

    @media (min-width: 1200px) {
        .pure-g {
            width: 1170px;
        }
    }
}