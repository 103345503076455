nav {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px 5px 100px;
    align-items: center;
    background-color: #fff;
    color: #000;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 8;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

    .login-btn {
        cursor: pointer;
    }

    .cart {
        position: relative;


        img {
            width: 50px;
        }

        span {
            position: absolute;
            top: 25%;
            left: 50%;
            font-size: 13px;
        }
    }

    .desktop-hidden {
        display: none;
    }

    .mobile-hidden {
        display: block;
    }

    img {
        width: 212px;
    }

    ul {
        list-style: none;
        display: flex;
        gap: 2rem;
        align-items: center;


        li {
            cursor: pointer;
            white-space: nowrap;
        }
    }

    .navButton {
        background-color: #fff;
        border-radius: 20px;
        border: 10px solid #fff;
        color: #000;
        margin-left: 2rem;
        margin-right: 1rem;
        height: 40px;
        margin-top: auto;
        margin-bottom: auto;
    }

    .selected {
        position: relative;

        &::before {
            content: '';
            background-color: #A31D42;
            width: 110%;
            position: absolute;
            bottom: -5px;
            height: 3px;

        }
    }

    .burgerMenu {
        height: 50px;
        width: 55px;
        filter: #000;
        cursor: pointer;
    }


}

@media only screen and (max-width: 1180px) {
    nav {
        padding: 5px 10px 5px 10px;
    }
}

@media only screen and (max-width: 1090px) {
    nav {
        padding: 5px 10px 5px 10px;
        position: fixed;

        .display-none {
            display: none;
        }

        .display-block {
            display: block;
        }

        .desktop-hidden {
            display: block;
        }

        .mobile-hidden {
            display: none;
        }



        ul {
            background-color: #fff;
            display: inline-block;
            position: fixed;
            right: 0px;
            top: 77px;
            padding: 0px 30px 30px 30px;
            box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);



            li {
                margin-bottom: 26px;
            }

            .navButton {
                margin-top: 500px;
            }
        }

        .selected {
            position: relative;

            &::before {
                content: '';
                background-color: #A31D42;
                width: 100%;
                position: absolute;
                bottom: -5px;
                height: 3px;

            }
        }
    }


}

a {
    text-decoration: none !important;
    color: #000 !important;
}