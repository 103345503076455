#states-section {
    h2 {
        font-size: 2rem;
        margin-bottom: 40px;
        margin-top: 20px;
    }

    .heading-secrion {
        text-align: center;
        color: #2A2A2A;

        h1 {
            margin-bottom: 20px;
        }

        p {
            display: block;
            margin: 0pc auto;
            width: 40%;
            text-align: left;
        }
    }

    .stats {
        display: flex;
        justify-content: center;
        gap: 150px;
        flex-wrap: wrap;

        .stat {
            margin-top: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 20px;

            img {
                padding: 10px;
                margin-right: 15px;
                width: 70px;
            }

            div {
                font-size: 1.1em;
                color: #f7a803;
                font-weight: 600;

                .description {
                    white-space: nowrap;
                }
            }
        }
    }
}

@media only screen and (max-width: 1060px) {
    #states-section {
        .stats {
            gap: 0px;
            flex-direction: column;

            .stat {
                margin: 0px;
            }
        }
    }
}

@media screen and (max-width: 1240px) {
    #states-section {
        .heading-secrion {
            p {
                width: 90vw;
            }
        }
    }
}