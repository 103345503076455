.faq-item {

    .header-grid {
        display: flex;

        justify-content: space-between;
        padding: 15px;

        cursor: pointer;

        &:hover {
            background-color: #c2c2c2;
        }
    }

    .d-none {
        display: none;
    }
}