.blog-Item {
    .blog-bottom {
        justify-content: center;
        align-items: center;
        // width: 40%;

    }

    .blog-right {
        display: flex;
        flex-wrap: wrap;
    }

    img {
        height: 100%;
        max-width: auto;
        display: block;
    }



    .img-container {
        height: 330px;
        width: auto !important;
        border-radius: 20px;
        box-shadow: 0px 50px 76;
        overflow: hidden;
        padding: auto;
        text-align: center;

        img {
            display: block;
        }
    }

    .content {
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        width: 350px;

        h2 {
            margin: 20px 0px;
        }

        .date {
            color: #b8b7b7;
        }

        a {
            border: solid 1px blue;
            color: #000;
            padding: 8px 12px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
            margin-top: 15px;
            border-radius: 5px;
        }
    }


}

.img-fake {
    background-color: #ebebeb;
    width: 390px;
    height: 300px;
    border-radius: 10px;

}

@media only screen and (max-width: 500px) {
    .blog-Item {
        img {
            width: 90%;
            height: auto;
            display: block;
            margin: 0px auto;
        }
    }

    .img-container {
        height: auto !important;
    }
}