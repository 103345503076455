#our-indesry {
    .mt {
        margin-top: 20px;
    }

    margin-top: 70px;
    color: #2A2A2A;

    .d-block-m {
        display: none;
    }

    .why-amasocial-content {
        text-align: left;
        display: flex;
        justify-content: center;

        div {
            width: 40%;
        }

        h2 {
            margin-bottom: 40px;
            text-align: center;
        }
    }

    .content-grid {
        display: flex;
        justify-content: end;
        width: 100%;

        .magic {
            display: flex;
            justify-content: end;
        }

        .img-section {
            width: 100%;

        }

        .img-container {
            background-color: #4D333B;
            clip-path: polygon(0 25%, 100% 0, 100% 100%, 0 75%);
            width: 490px;
            height: 500px;
            position: relative;

            &::after {
                //small circle
                content: '';
                width: 230px;
                height: 230px;
                border-radius: 50%;
                background-color: #6F5B61;
                position: absolute;
                top: 30%;
                left: 13%;
            }

            &::before {
                //big circle
                content: '';
                width: 390px;
                height: 390px;
                border-radius: 50%;
                background-color: #5F484F;
                position: absolute;
                top: 14%;
                left: -2%;
            }

            img {
                width: 170px;
                position: absolute;
                top: 35%;
                left: 19%;
                z-index: 2;
            }
        }

        .content-section {
            display: flex;
            justify-content: end;
            align-items: center;
            width: 80%;

            h2 {
                font-size: 2rem;
                margin-bottom: 40px;
                margin-top: 20px;
            }

            div {
                height: 250px;
                width: 600px;
            }
        }
    }



}

@media screen and (max-width: 1240px) {
    #our-indesry {
        .d-block-m {
            display: block;
            margin-bottom: 50px;
        }

        .d-block-d {
            display: none;
        }

        .why-amasocial-content {
            div {
                width: 90vw;
            }
        }
    }
}