// $test: 150px;

// :root {
//     --fs-xl: clamp(3.5rem, 12vw + 1rem, 10rem);
//     --fs-600: 2rem;
//     --fs-400: 1rem;
// }

// @media only screen and (min-width: 40em) {
//     :root {
//         --fs-xl: 15vw;
//         --fs-600: 2rem;
//         --fs-400: 1rem;
//     }
// }


* {
    margin: 0px;
    box-sizing: border-box;
}

.f-center {
    display: flex;
    justify-content: center;
}

body {
    background-color: #F9F9FF;
}

.center {
    text-align: center;
}


h1 {
    font-size: clamp(0.5rem, 12vw + 1rem, 3rem) !important;
}

h2 {
    font-size: clamp(0.5rem, 12vw + 1rem, 1.5rem) !important;
}

// @media only screen and (max-width: 700px) {
//     h1 {
//         font-size: 2rem !important;
//     }

//     h2 {
//         font-size: 1.5rem !important;
//     }
// }