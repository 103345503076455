#landing-Page {
    .wave-container {
        height: 90vh;
        background-color: rgb(153, 96, 96);
        position: relative;
        background-color: #F9F9FF;
        overflow: hidden;

        .x-logo {
            width: 50px !important;
        }

        .content-container {
            position: absolute;
            height: 500px;
            left: 0px;
            color: #fff;
            z-index: 3;
            display: flex;
            justify-content: space-between;

            .header {
                padding: 50px;
                padding-top: 150px;

                .header-content {
                    width: 70%;
                }
            }

            .icon-section {
                width: 100%;
                margin-right: 100px;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                .icon-collection {
                    width: 300px;
                    height: 300px;
                    margin-top: 90px;

                    img {
                        width: 70px;
                    }

                    p {
                        white-space: nowrap;
                        transform: translateX(-60px);
                    }

                    .row {
                        margin-bottom: 40px;
                    }

                    .row-1 {
                        display: flex;
                        justify-content: space-around;
                    }

                    .row-2 {
                        display: flex;
                        justify-content: space-between;
                    }

                    .row-3 {
                        display: flex;
                        justify-content: center;
                    }
                }
            }

            @media screen and (max-width: 1240px) {
                .icon-section {
                    display: none;
                }

                .header-content {
                    margin: 0px;
                }
            }

            h1 {
                font-size: 55px;
                margin-bottom: 40px;
                font-weight: normal;
            }


        }

        &:before,
        &:after {
            content: "";
            position: absolute;
            left: 50%;
            min-width: 300vw;
            min-height: 300vw;
            background-color: #4E333B;
            animation-name: rotate;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }

        &:before {
            bottom: 1vh; //Gap between
            // border-radius: 59%;
            animation-duration: 60s;
        }

        &:after {
            bottom: 3vh;
            opacity: .5;
            border-radius: 48%;
            animation-duration: 60s;
        }

        @media screen and (max-width: 1240px) {
            .icon-section {
                display: none;
            }
        }
    }

    @keyframes rotate {
        0% {
            transform: translate(-50%, 0%) rotateZ(360deg);
        }

        50% {
            transform: translate(-50%, 0%) rotateZ(360deg);
        }

        100% {
            transform: translate(-50%, 0%) rotateZ(360deg);
        }
    }

    @media screen and (max-width: 1240px) {
        .wave-container {
            height: 95vh;
        }

        .header-content {
            width: 100% !important;

        }

        br {
            display: none;
        }
    }
}