#footer {
    background-color: #EBEBF8;
    position: relative;
    margin-top: 15px;

    &::before {
        content: '';
        position: absolute;
        top: 0px;
        width: 100%;
        height: 3px;
        background-color: #A6264A;
    }

    .grid-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

    }

    .section {
        padding: 20px;
        height: 100%;

        h3 {
            margin-bottom: 5px;
        }

        ul {
            list-style: none;
            padding: 0px;
            padding-left: 1px;
            flex: 25%;

            li {
                margin-bottom: 5px;
                color: #888888;
            }

            a {
                color: #888888;
                text-decoration: none;
            }
        }
    }

    .logo-section {
        text-align: left;

        p {
            margin-top: 10px;
            font-size: small;
            color: #888888;
        }

        img {
            width: 212px;
        }
    }
}

@media only screen and (max-width: 700px) {
    .grid-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .section {
        width: 90%;
    }

}